import LINK from '@/utils/Constants/Link';

export const goHome = () => {
  window.location.href = DLG.config.encodedContextPath;
};
export const goLogin = () => {
  window.location.href = LINK.LOGIN;
};
export const goRegister = () => {
  window.location.href = LINK.REGISTER;
};
export const goToContactUs = () => {
  window.location.href = LINK.CONTACT_US;
};
export const goProductRegistrationLanding = () => {
  window.location.href = LINK.PRODUCT_REGISTRATION.LANDING;
};
export const goToProductRegistrationSearch = () => {
  window.location.href = LINK.PRODUCT_REGISTRATION.SEARCH;
};
export const goToCart = () => {
  window.location.href = LINK.CART;
};
export const goToRecipes = () => {
  window.location.href = LINK.RECIPES;
};
export const goToMyAccountSavedCarts = () => {
  window.location.href = LINK.ACCOUNT.SAVED_CARTS;
};
export const goToMyAccountWishlist = () => {
  window.location.href = LINK.ACCOUNT.WISHLIST;
};
export const goToCheckout = () => {
  window.location.href = LINK.CHECKOUT.STEP_ONE;
};

export const goToCheckoutThankyou = orderNumber => {
  window.location.href = `${LINK.CHECKOUT.THANK_YOU}/${orderNumber}`;
};

export const goToMyRegisteredProducts = () => {
  window.location.href = LINK.ACCOUNT.REGISTERED_PRODUCT;
};
export const goToWhereToBuy = () => {
  window.location.href = LINK.PRODUCT.WHERE_TO_BUY;
};
export const goToPage = url => {
  window.location.href = url;
};
export const reloadPage = () => {
  window.location.reload();
};

export const goWishlist = () => {
  window.location.href = LINK.WISHLIST;
};

export const goToFaqsSearch = q => {
  window.location.href = `${LINK.FAQS}/search?q=${q}`;
};

export const goToFaqsPage = () => {
  window.location.href = LINK.FAQS;
};

export const goToReturnPolicy = () => {
  window.location.href = LINK.RETURN_POLICY;
};

export const goToManualsSearch = q => {
  window.location.href = `${DLG.config.encodedContextPath}/manuals/search?q=${q}`;
};

export const goDirectToManuals = p => {
  window.location.href = `${DLG.config.encodedContextPath}/manuals${p}`;
};

export const goToRegistrationPage = () => {
  window.location.href = LINK.REGISTER;
};

export const goToOrderHistory = () => {
  window.location.href = LINK.ORDER.HISTORY;
};

export const goToOrderDetail = orderCode => {
  window.location.href = `${LINK.ORDER.HISTORY}/${orderCode}`;
};

export const goToRegisterProductDetail = (productCode, productSN) => {
  window.location.href = `${
    LINK.PRODUCT_REGISTRATION.DETAIL
  }?code=${productCode}${productSN ? `&sn=${productSN}` : ''}`;
};

export const goToRegisterProductUserDetail = (productCode, productSN) => {
  window.location.href = `${
    LINK.PRODUCT_REGISTRATION.USER_DETAIL
  }?code=${productCode}${productSN ? `&sn=${productSN}` : ''}`;
};

export const goToGuestOrderDetail = orderCode => {
  window.location.href = `${LINK.ORDER.GUEST}/${orderCode}`;
};

export const goToSearch = text => {
  window.location.href = `${LINK.SEARCH}?text=${text}`;
};

export const goToSubscriptionHistory = () => {
  window.location.href = `${LINK.SUBSCRIPTION.HISTORY}`;
};

export const goToSubscriptionDetail = subscriptionCode => {
  window.location.href = `${LINK.SUBSCRIPTION.DETAIL}?id=${subscriptionCode}`;
};
